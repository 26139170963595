import React, { Fragment } from 'react';
import Banner from '../../components/Banner';
import { Row, Col } from '../../components/Grid';
import Button from '../../components/Button';
import Media from '../../components/Media';
import { Header, Footer } from '../../containers/index';
import { RecommendData } from '../../mock/document';

import './index.scss';
import mlistImg1 from './../../mock/img/DocumentCenter/元商店协议.svg';
import mlistImg2 from './../../mock/img/DocumentCenter/可信存证.svg';

function DocumentCenter() {
  return (
    <Fragment>
      <Header />
      <Banner className="dc-page-hero" style={{ backgroundColor: '#000' }}>
        <div className="dc-page-hero__title">文档中心</div>
      </Banner>
      <section className="dc-page-manual">
        <div className="dc-page-manual__inner">
          <Row gutter={[24, 24]} className="show-pc">
            {RecommendData.map((item, index) => (
              <Col colSpan={12} key={`col-${index}`}>
                <Media
                  data={item}
                  radius
                  card
                  shadow
                  horizon
                  opposite
                  btns={
                    index === 0 ? (
                      <Button
                        primary
                        onClick={() =>
                          window.open('https://console.zxchain.qq.com/docs?docpath=doc/content/快速入门/准备工作.html')
                        }
                      >
                        入门手册
                      </Button>
                    ) : (
                      <>
                        <Button
                          primary
                          onClick={() =>
                            window.open(
                              'https://console.zxchain.qq.com/docs?docpath=doc/content/API文档/元商品协议相关接口/用户相关接口/简介.html',
                            )
                          }
                        >
                          元商品协议
                        </Button>
                        <Button onClick={() => window.open('https://console.zxchain.qq.com/docs?docpath=doc/content/API文档/可信存证相关接口/简介.html')}>
                          可信存证
                        </Button>
                      </>
                    )
                  }
                />
              </Col>
            ))}
          </Row>
          <div className="dc-page-manual-moblie show-moblie">
            <div className="dc-page-manual-moblie__card entry">
              <div className="dc-page-manual-moblie__head">
                <div className="title">至信链</div>
                <div className="desc">至信链开放联盟链</div>
              </div>
              <div className="dc-page-manual-moblie__body">
                <img src={RecommendData[0].img} alt="至信链" />
              </div>
              <div className="dc-page-manual-moblie__foot">
                <Button
                  primary
                  onClick={() =>
                    window.open('https://console.zxchain.qq.com/docs?docpath=doc/content/快速入门/准备工作.html')
                  }
                >
                  入门手册
                </Button>
              </div>
            </div>

            <div className="dc-page-manual-moblie__card app">
              <div className="dc-page-manual-moblie__title">应用服务</div>
              <div className="dc-page-manual-moblie__lists">
                <a href="##" className="dc-page-manual-moblie__item">
                  <div
                    className="icon"
                    style={{
                      backgroundImage: `url(${mlistImg1})`,
                    }}
                  ></div>
                  <div
                    className="title"
                    onClick={() =>
                      window.open(
                        'https://console.zxchain.qq.com/docs?docpath=doc/content/元商品协议相关接口/用户相关接口简介.html',
                      )
                    }
                  >
                    元商品协议
                  </div>
                </a>
                <a href="https://console.zxchain.qq.com/docs?docpath=doc/content/API文档/可信存证相关接口/简介.html" className="dc-page-manual-moblie__item">
                  <div
                    className="icon"
                    style={{
                      backgroundImage: `url(${mlistImg2})`,
                    }}
                  ></div>
                  <div className="title">可信存证</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default DocumentCenter;
